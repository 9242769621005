import React from "react"
import AdvantagesHeaderSection from "../../sections/AdvantagesHeaderSection"
import Layout from "../../components/Layout"
import SideNavMenuSection from "../../sections/SideNavMenuSection"
import UserSideNavSection from "../../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../../sections/login_signup_side_navs/SchoolSideNavSection"
import IndexArticleGroupsSection from "../../sections/IndexArticleGroupsSection"
import ArticleGroupFooterSection from "../../sections/ArticleGroupFooterSection"

const ArticleGroupsPage = props => {
  return (
    <Layout
      wrapperClass={"school-item info-page"}
      pageTitle={`${props.pageContext.articlesGroup.name} | Smart Admissions Service`}
      keywordsContent={props.pageContext.articlesGroup.metaKeywords}
      descriptionContent={props.pageContext.articlesGroup.metaDescription}
      isSecondLayout={true}
    >
      <AdvantagesHeaderSection />
      <IndexArticleGroupsSection
        articlesGroup={props.pageContext.articlesGroup}
        articlesGroupsArticles={props.pageContext.articlesGroupsArticles}
      />
      <ArticleGroupFooterSection />

      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection />
    </Layout>
  )
}
export default ArticleGroupsPage
