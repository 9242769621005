import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, navigate } from "gatsby"
import ArrowLeftIconDark from "../svg/arrow-left-dark.inline.svg"

import { changeTitleRout } from "../scripts/Utils"

const IndexArticleGroupsSection = (props) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const [programArray, setProgramArray] = useState([])
  useEffect(async () => {
    setProgramArray(props.articlesGroupsArticles)
  }, [])
  const backToPage = () => {
    navigate(`/${lang}#handbooks`)
  }
  return (
    <div className="school-items-map-view-wrapper article-groups container">
      <div className="row">
        <div className="white-block school-comparison-main-info school-comparison-main-info-title col-12 my-5 d-flex gap-4">
          <div
            onClick={backToPage}
            className="school-comparison-main-info-left"
          >
            <ArrowLeftIconDark className="arrow_left_dark" />
          </div>
          <div>
            {props.articlesGroup ? (
              <div className="title-forgot-password">
                {props.articlesGroup.name}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        {programArray &&
          programArray.map(item => {
            return (
              <Link
                to={`/${lang}/article/${changeTitleRout(item.engTitle)}`}
                className="col-12 px-7"
                key={item.title}
              >
                <div className="school-item-comparison-wrapper f-jb">
                  <div className="school-item-comparison-image-description f-js row">
                    <img
                      src={item.resolvedImageUrl}
                      alt=""
                      className="school-comparison-image col-lg-4"
                    />
                    <div className="school-comparison-main-info col">
                      <div className="saved-school-title">{item.title}</div>
                      <div className="saved-school-text">
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="school-items-map-view-wrapper">
                  <div
                    key={item.name}
                    className="school-item-comparison-wrapper f-jb"
                  >
                    <div className="school-item-comparison-image-description f-jb">
                      <img
                        src={item.resolvedImageUrl}
                        alt=""
                        className="school-comparison-image"
                      />
                      <div className="school-comparison-main-info">
                        <div className="saved-school-title">{item.title}</div>
                        <div className="saved-school-text school-levels">
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Link>
            )
          })}
      </div>
    </div>
  )
}
export default IndexArticleGroupsSection
