import React from "react"
import { useTranslation } from "react-i18next"

const Copyright = () => {
  const { t } = useTranslation()

  const currentYear = new Date().getFullYear()

  return (
    <div>
      © {currentYear} Business Link. {t("school.footer.rights")}
    </div>
  )
}

export default Copyright
