import React, { useState } from "react"
import { Link, navigate } from "gatsby"

import { openNav } from "../scripts/sideNavMenu.js"

import SearchIconWhite from "../svg/search-white.inline.svg"

import MenuIconDark from "../svg/menu-left-black.inline.svg"

import ChangeChoiceLanguage from "../components/ChangeChoiceLanguage"
import UserLoginName from "./UserLoginName"
import { useTranslation } from "react-i18next"
import { getHeaderClasses } from "../scripts/Utils"

const AdvantagesHeaderSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [shouldBeActive] = useState(true)
  const [allSelectTypes, setAllSelectTypes] = useState({
    searchInput: [],
  })

  const { triggered } = getHeaderClasses(shouldBeActive)

  const handleChange = e => {
    const { name, value } = e.target
    setAllSelectTypes(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    navigate(
      `/${i18n.language}/result-map-page?searchInput=${encodeURIComponent(
        allSelectTypes.searchInput.toString()
      )}`
    )
  }

  return (
    <div className="header-wrapper info-page-header">
      <div className="header container py-4 px-5">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-lg-4 d-flex justify-content-center">
            {/* LOGO */}
            <Link to={`/${lang}/`}>
              <div className="logo_full mR-20">
                <div className="logo_full_block logo_full_block_1">Smart</div>
                <div className="logo_full_block logo_full_block_2">
                  Admissions
                </div>
                <div className="logo_full_block logo_full_block_3">Service</div>
                <div className="logo_shrinked_block">
                  <pre>S A S</pre>
                </div>
              </div>
            </Link>
            {/* MENU BUTTON */}
            <div className="icon_button triggered menu " onClick={openNav}>
              <MenuIconDark
                className={`menu_icon shrinked-menu-icon dark_menu`}
              />
              {/* <div className="menu_icon dark_menu"></div> */}
              <div className="menu_title active right">
                {t("school.header.menu")}
              </div>
            </div>
          </div>
          {/* SEARCH */}
          {/* <div className="col-lg-4 d-flex hidden-search justify-content-center">
            <div className={`icon_button shrinked_search ${triggered}`}>
              <input
                name="searchInput"
                onChange={handleChange}
                placeholder={t("main.header.search")}
                id="searchList"
                className="search-input"
                type="text"
                list="arr"
              />
              <div
                onClick={handleFormSubmit}
                className="show-all-button shrinked-search-button"
              >
                <SearchIconWhite className="search-icon search-white" />
              </div>
            </div>
          </div> */}

          <div className="col-lg-4 d-flex align-items-center justify-content-center">
            {/* SCHOOL LOGIN */}
            <UserLoginName pathName={"dark_menu"} />

            {/* LANGUAGE */}
            <ChangeChoiceLanguage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvantagesHeaderSection
