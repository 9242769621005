import Link from "gatsby-link"
import React from "react"
import { useTranslation } from "react-i18next"
import Copyright from "../components/copyright"

const ArticleGroupFooterSection = () => {
  const { i18n, t } = useTranslation()
  const lang = i18n.language
  return (
    <div className="bottom-block d-flex align-items-end py-7">
      <div className="bottom-info-wrapper container">
        <div className="row">
          <div className="bottom-info-left-block col-lg-6 d-flex gap-3">
            <Copyright />
            <div className="">·</div>

            <Link to={`/${lang}/privacy`}>
              <div className="bottom-link">{t("school.footer.privacy")}</div>
            </Link>
            <div className="">·</div>
            <Link to={`/${lang}/terms`}>
              <div className="bottom-link terms">
                {t("school.footer.terms")}
              </div>
            </Link>
          </div>
          <div className="bottom-info-right-block col-lg-6 d-flex gap-3 justify-content-end">
            <a target="_blank" href="https://www.facebook.com/businesslinkkyiv">
              <div className="bottom-link">Facebook</div>
            </a>
            <div className="">·</div>
            <a
              target="_blank"
              href="https://www.instagram.com/businesslink.com.ua/"
            >
              <div className="bottom-link">Instagram</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleGroupFooterSection
